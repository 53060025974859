import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "../Components/header/Header";
import Sidebar from "../Components/sidebar/Sidebar";
import Claims from "./claims-history/claims";
import SearchClaim from "./claims-history/SearchClaim";
import UploadHistory from "./claims-history/UploadHistory";
import Login from "./login/Login";
import { Context } from '../context/context';
import Payment from "./payment/Payment";

const StackScreen = () => {
  // console.log(Context._currentValue)
  return (
    <>
    <Context.Provider value={Context}>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-wrapper-inner">
          <Routes>
            <Route path="/" element={<Navigate replace to="/upload-history" />} />
            <Route exact path="/upload-history" element={<UploadHistory />} />
            <Route exact path="/file-history/fileID-:id" element={<SearchClaim />} />
            <Route exact path="/claim-history/claimID-:id" element={<Claims />} />
            <Route exact path="/payment" element={<Payment />} />
          </Routes>
        </div>
      </div>
      </Context.Provider>
    </>
  );
};
export default StackScreen;
