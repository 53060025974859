import MainNavigation from "./MainNavigation";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <MainNavigation />
    </>
  );
}

export default App;
