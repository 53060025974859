import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// images
import background from "../../assets/img/bg-01.jpg";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordIcon, setPasswordIcon] = useState(false);

  const userLoggedIn = async () => {
    try {
      const response = await axios({
        method: "POST",
        data: {
          user: username,
          password: password,
        },
        url: "https://emrtest.genensys.com/clearinghouse/login",
      });
      // console.log(response);
      if (response.data.token) {
        // navigate("/claims");
        localStorage.setItem("token", response.data.token);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }

    console.log(username);
    console.log(password);
  };

  return (
    <div className="limiter">
      <div
        className="container-login100"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="wrap-login100">
          <form className="login100-form validate-form">
            <div className="login100-form-logo">
              <i className="fas fa-user"></i>
            </div>

            <span className="login100-form-title my-4">Log in</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Enter username"
            >
              <input
                className="input100"
                type="text"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <span className="focus-input100" data-placeholder="f007;">
                <i className="fa fa-user"></i>
              </span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Enter password"
            >
              <input
                className="input100"
                type={passwordIcon ? "type" : "password"}
                name="pass"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="focus-input100" data-placeholder="f06e">
                <i className="fa fa-lock"></i>
              </span>
              <i
                className={`${
                  passwordIcon ? "fa fa-eye" : "fa fa-eye-slash"
                } position-absolute end-0 top-0 mt-3 me-3 text-white cursor-pointer`}
                onClick={() => setPasswordIcon(!passwordIcon)}
              ></i>
            </div>

            <div className="contact100-form-checkbox">
              <input
                className="input-checkbox100"
                id="ckb1"
                type="checkbox"
                name="remember-me"
              />
              <label className="label-checkbox100" htmlFor="ckb1">
                Remember me
              </label>
            </div>

            <div className="container-login100-form-btn">
              <button
                className="login100-form-btn"
                type="button"
                onClick={userLoggedIn}
              >
                Login
              </button>
            </div>

            <div className="text-center my-2">
              <a className="txt1">Forgot Password?</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
