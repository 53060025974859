import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
// images
import logo from "../../assets/img/logo.png";

const Sidebar = () => {
  let navigate = useNavigate();
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    setDropDown(false);
    console.log('---')
  }, []);
  // const [matchRoute, setDropDown] = useState(false);
  // if (condition) {

  // }

  let matchRoute;

  if (window.location.pathname.includes("/file-history/fileID-")) {
    matchRoute = true;
  }
  if (window.location.pathname.includes("/claim-history/claimID-")) {
    matchRoute = true;
  }

  switch (window.location.pathname) {
    case "/claims":
      matchRoute = true;
      break;
    case "/upload-history":
      matchRoute = true;
      break;
  }
  // console.log(matchRoute)
  return (
    <div className="sidebar-content-wrapper">
      <ul className="navigation">
        <li className="logo" data-item="">
          <img src={logo} alt="" />
        </li>
        <hr className="divider m-b-0" />
        <li className="nav-item dropdown" data-item="dashboard">
          <div className="item" onClick={() => setDropDown(!dropDown)}>
            <span>
              <i className="fa fa-book-medical"></i>
              <p className="m-0">Claims</p>
            </span>
            <i
              className={`fas drop-icon ${
                dropDown ? "fa-chevron-right" : "fa-chevron-down"
              }`}
            ></i>
          </div>
          {dropDown &&
            <div className="drop-items">
              <h6>Patients Claims:</h6>
              <NavLink
                to="/claimsdasd"
                className={`drop-item ${matchRoute ? "" : ""}`}
              >
                Upload Files
              </NavLink>
              <NavLink
                to="/claimsdasda"
                className={`drop-item ${matchRoute ? "" : ""}`}
              >
                EDI Reports
              </NavLink>
              <NavLink
                to="/upload-history"
                className={`drop-item ${
                  window.location.pathname === "/upload-history"
                    ? "active"
                    : ""
                }`}
              >
                Upload History
              </NavLink>
              <NavLink
                to="/file-history/fileID-"
                className={`drop-item ${
                  window.location.pathname.includes("/file-history/fileID-")
                    ? "active"
                    : ""
                }`}
              >
                File History
              </NavLink>
              <NavLink
                to="/claim-history/claimID-"
                className={`drop-item ${
                  window.location.pathname.includes("/claim-history/claimID-")
                    ? "active"
                    : ""
                }`}
              >
                Claims History
              </NavLink>
            </div>
          }
        </li>
        <li className="nav-item" data-item="eras">
          <div className="item">
            <span>
              <i className="fa fa-file-medical-alt"></i>
              <p className="m-0">ERAs</p>
            </span>
          </div>
        </li>
        <li className="nav-item" data-item="eligibility">
          <div className="item">
            <span>
              <i className="fa fa-book-medical"></i>
              <p className="m-0">Eligibility</p>
            </span>
          </div>
        </li>
        <li className="nav-item" data-item="Payment">
          <div className="item">
            <NavLink to="/payment">
            <span>
              <i className="fa fa-file-medical-alt"></i>
              <p className="m-0">Payment</p>
            </span>
            </NavLink>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
