import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Payment = () => {
  return (
    <div className="p-l-10 p-r-10 p-t-20">
      <h1 className="mb-4">Payment</h1>
      <div className="mb-4 d-flex flex-wrap">
        <div className="form-field flex-shrink-0 me-4 payment-header">
          <label className="fw-bold mb-2">Eft No.</label>
          <input
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 me-4">
          <label className="fw-bold mb-2">Payee Name</label>
          <input
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 me-4">
        <label className="fw-bold mb-2">Payee Id</label>
          <input
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 me-4">
        <label className="fw-bold mb-2">Payer Name</label>
          <input
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 me-4">
          <label className="fw-bold mb-2">Start Date</label>
          <input
            type="date"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 me-4">
          <label className="fw-bold mb-2">End Date</label>
          <input
            type="date"
            className="form-control"
          />
        </div>
        <div className="form-field flex-shrink-0 align-self-end">
          <button className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>EFT No. </th>
              <th>View</th>
              <th>Download</th>
              <th>Tax ID/SSN</th>
              <th>Payee Name</th>
              <th>Payee ID</th>
              <th>Date Received</th>
              <th>Payment Method Code</th>
              <th>Check Amt</th>
              <th>Payer Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>151900</td>
              <td></td>
              <td></td>
              <td>260666</td>
              <td>Can do therapies Inc</td>
              <td>1369938</td>
              <td>02/01/23</td>
              <td>ACH</td>
              <td>$154.73</td>
              <td>Therapy Network Medcaid</td>
            </tr>
          </tbody>
          {/* <tbody>
            {uploadSearchData &&
              uploadSearchData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/file-history/fileID-${item.fileId}`}>{item.fileId}</Link>
                    </td>
                    <td>{item.clientId}</td>
                    <td>{item.fileName}</td>
                    <td>{item.fileStatus}</td>
                    <td>{item.processed}</td>
                    <td>{item.processedTime}</td>
                    <td>{item.batchNumber}</td>
                    <td>{item.totalClaims}</td>
                    <td>{item.totalErrors}</td>
                    <td>{item.uploadedTime}</td>
                  </tr>
                );
              })}
          </tbody> */}
        </table>
      </div>
    </div>
  )
}

export default Payment