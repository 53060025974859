import { createContext } from "react";
let pageTitle;
switch (window.location.pathname) {
  case "/claims":
    pageTitle = window.location.pathname.includes("/claims/fileId:") && "File History";
    break;
  case "/upload-history":
    pageTitle = "File History";
    break;
}
export const Context = createContext(pageTitle);
