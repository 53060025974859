import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const SearchClaim = () => {
  // navigate
  let navigate = useNavigate();

  // useState
  const [uploadSearchData, setUploadSearchData] = useState([]);

  // useEffect
  useEffect(() => {
    searchFileData();
  }, []);

  // useParams get id from url
  let userId = useParams();
  console.log(userId.id);

  // Api Sync Await
  const searchFileData = async () => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        url: `https://emrtest.genensys.com/clearinghouse/searchclaim?fileId=${userId.id}`,
      });
      setUploadSearchData(response.data.data);
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // on click page redirect
  const claimId = (id) => {
    console.log(id);
    navigate(`claimId:${id}`);
  };

  // console.log(userId)

  return (
    <div className="p-l-10 p-r-10 p-t-20">
      <h1 className="mb-4">File History</h1>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Amt</th>
              <th>Claim Id </th>
              <th>Claim Status </th>
              <th>Client Id</th>
              <th>Date of Services</th>
              <th>File Id</th>
              <th>First Name</th>
              <th>Id</th>
              <th>Last Name</th>
              <th>Patient Id</th>
              <th>Payer Name</th>
            </tr>
          </thead>
          <tbody>
            {uploadSearchData &&
              uploadSearchData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.amt}</td>
                    <td>
                      <Link to={`/claim-history/claimID-${item.claimId}`}>
                        {item.claimId}
                      </Link>
                      {/* {item.claimId} */}
                    </td>
                    <td>{item.claimStatus}</td>
                    <td>{item.clientId}</td>
                    <td>{item.dateOfService}</td>
                    <td>{item.fileId}</td>
                    <td>{item.firstName}</td>
                    <td>{item.id}</td>
                    <td>{item.lastName}</td>
                    <td>{item.patientId}</td>
                    <td>{item.payerName}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchClaim;
