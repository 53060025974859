import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthScreen from "./Screens/AuthScreen";
import "./assets/css/style.css";
import StackScreen from "./Screens/StackScreen";

const MainNavigation = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  return (
    <>
      <BrowserRouter>
      {
        token == null ? <AuthScreen /> : <StackScreen />
      }
      </BrowserRouter>
    </>
  );
};

export default MainNavigation;
