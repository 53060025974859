import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../Components/header/Header";
import Sidebar from "../Components/sidebar/Sidebar";
import Claims from "./claims-history/claims";
import Login from "./login/Login";

const AuthScreen = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </>
  );
};
export default AuthScreen;
