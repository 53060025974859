import {
  LOGIN
} from "../Actions/actionType";

const INITIAL_STATE = {
  isApiCall: false,
};

export function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
