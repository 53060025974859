import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// images

const Claims = () => {
  let navigate = useNavigate();

  const [claimData, setClaimData] = useState("");
  const [claimStatus, setClaimStatus] = useState([]);
  const [claimApi, setClaimApi] = useState([]);


  useEffect(() => {
    searchFileData();
  }, []);

  let claimId = useParams();

  // console.log(claimId.id);

  const searchFileData = async () => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        // url: `https://emrtest.genensys.com/clearinghouse/claimstatus?claimId=198035`,
        url: `https://emrtest.genensys.com/clearinghouse/claimstatus?claimId=${claimId.id}`,
      });
      await setClaimStatus(response?.data?.status[0]);
      await setClaimData(response?.data.claimData);
      // await setClaimData(response?.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(claimStatus)


  return (
    <div className="p-l-10 p-r-10 p-t-20">
      <h1>Claims</h1>
      <div className="claim-data-container">
        <div className="claim-data-textbox">{claimData !== "" && claimData}</div>

        <div className="mt-5">
          <h4 className="fw-bold mb-3">Clients Status</h4>
          {/* <div className="row g-0 mt-4"> */}

          {claimStatus?.map((item, index) => {
              // console.log(item.date)
              return (
                // <div className="col-6">

                <div key={index} className="row g-0 status-row">
                  <div className="col-4">
                    <span className="fw-bold">Date : </span>
                    <span>{item.date}</span>
                  </div>
                  <div className="col-4">
                    <span className="fw-bold">Status : </span>
                    <span>{item.status}</span>
                  </div>
                </div>
                // </div>
              );
            })}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Claims;
