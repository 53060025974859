import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const UploadHistory = () => {
  // navigate
  let navigate = useNavigate();

  // useState
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [uploadSearchData, setUploadSearchData] = useState([]);

  // date set
  var todayDate = new Date().getDate();
  var currentMonth = new Date().getMonth() + 1;
  var currentYear = new Date().getFullYear();

  if (currentMonth < 10) {
    currentMonth = "0" + currentMonth;
  }

  if (todayDate < 10) {
    todayDate = "0" + todayDate;
  }

  var changeDate = new Date(currentMonth + " " + todayDate + " " + currentYear);
  changeDate.setDate(changeDate.getDate() - 7);
  var updatedDate = changeDate.toLocaleDateString().split("/");

  if (updatedDate[0] < 10) {
    updatedDate[0] = "0" + updatedDate[0];
  }

  if (updatedDate[1] < 10) {
    updatedDate[1] = "0" + updatedDate[1];
  }
  var defaultUpdatedDate = `${updatedDate[2]}-${updatedDate[0]}-${updatedDate[1]}`;
  var defaultDate = `${currentYear}-${currentMonth}-${todayDate}`;

  // useEffect
  useEffect(() => {
    uploadFileData(defaultUpdatedDate, defaultDate);
  }, []);

  // Api Sync Await
  const uploadFileData = async (startDate, endDate) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        url: `https://emrtest.genensys.com/clearinghouse/searchfile?startDate=${startDate}&endDate=${endDate}`,
      });
      setUploadSearchData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Date Change Async Await
  const handleSubmit = () => {
    if (startDate && endDate) {
      uploadFileData(startDate, endDate);
      setStartDate("");
      setEndDate("");
    }
  };

  // if (startDate && endDate) {
  //   // uploadFileData(startDate, endDate);
  //   setStartDate("")
  //   setEndDate("")
  //   console.log(startDate, endDate)
  // }

  // console.log(startDate)

  return (
    <div className="p-l-10 p-r-10 p-t-20">
      <h1 className="mb-4">Upload History</h1>
      {/* <p>{currentMonth + " " + todayDate + " " + currentYear}</p>
      <p>3 month before today : {updatedDate}</p> */}
      <div className="mb-4 d-flex">
        <div className="form-field flex-shrink-0 me-4">
          <label className="fw-bold mb-2">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="form-field flex-shrink-0">
          <label className="fw-bold mb-2">End Date</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="ms-4 form-field flex-shrink-0 align-self-end">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>File Id </th>
              <th>Client Id</th>
              <th>File Name</th>
              <th>File Status</th>
              <th>Processed</th>
              <th>Processed Time</th>
              <th>Batch Number</th>
              <th>Total Claims</th>
              <th>Total Errors</th>
              <th>Uploaded Time</th>
            </tr>
          </thead>
          <tbody>
            {uploadSearchData &&
              uploadSearchData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/file-history/fileID-${item.fileId}`}>{item.fileId}</Link>
                    </td>
                    <td>{item.clientId}</td>
                    <td>{item.fileName}</td>
                    <td>{item.fileStatus}</td>
                    <td>{item.processed}</td>
                    <td>{item.processedTime}</td>
                    <td>{item.batchNumber}</td>
                    <td>{item.totalClaims}</td>
                    <td>{item.totalErrors}</td>
                    <td>{item.uploadedTime}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UploadHistory;
